<template>
    <UDashboardLayout>
        <UDashboardPage>
            <UDashboardPanel grow>
                <UDashboardPanelContent>
                        <slot />
                </UDashboardPanelContent>
            </UDashboardPanel>
        </UDashboardPage>
    </UDashboardLayout>
</template>
<script setup lang="ts">
const { isAuthLoading, authLoadingTitle, authLoadingMessage } = useAuthLoading();
</script>